// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/utils/MeiliSearch.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/utils/MeiliSearch.tsx");
  import.meta.hot.lastModified = "1709112347291.6187";
}
// REMIX HMR END

import { Text } from "@chakra-ui/react";
import { MeiliSearch } from "meilisearch";
function parseHitToDealHit(hit) {
  return {
    ...hit
  };
}
function parseHitToCommentHit(hit) {
  return {
    ...hit
  };
}
function parseHitToPromoHit(hit) {
  return {
    ...hit
  };
}
function parseHitToCategory(hit) {
  return {
    ...hit
  };
}
function getCategoryById(id, items) {
  if (items) {
    for (const item of items) {
      if (item.id == id) {
        return item;
      }
    }
  }
  return null;
}
function parseFacetDistribution(facetDistribution, facetName, limit, categories) {
  const items = [];
  if (!facetDistribution) {
    return items;
  }
  for (const key in facetDistribution) {
    if (Object.prototype.hasOwnProperty.call(facetDistribution, key) && facetName == key) {
      const list = facetDistribution[key];
      for (const key in list) {
        const count = list[key];
        const item = {
          count: count,
          id: key,
          label: getCategoryById(key, categories)?.title,
          icon: getCategoryById(key, categories)?.icon
        };
        items.push(item);
      }
    }
  }
  items.sort((a, b) => b.count - a.count);
  const slicedArray = items.slice(0, limit);
  //   console.log(slicedArray);
  return slicedArray;
}
function getMeiliSearchClient() {
  let host;
  let apiKey;
  if (typeof process !== "undefined") {
    host = process.env.REACT_APP_MEILI_HOST;
    apiKey = process.env.MEILI_SEARCH_KEY;
  } else if (window && window?.ENV) {
    host = window.ENV.PUBLIC_REACT_APP_MEILI_HOST;
    apiKey = window.ENV.PUBLIC_REACT_APP_MEILI_SEARCH_KEY;
  }
  if (!host) {
    throw new Error("MEILISEARCH_HOST is not defined");
  }
  return new MeiliSearch({
    host,
    apiKey
  });
}
async function search(query, options, index = "deal") {
  const client = getMeiliSearchClient();
  // search for the query in the index
  const {
    hits
  } = await client.index(index).search(query, options);
  return hits;
}
async function searchTotal(query, options, index = "deal", total = 25) {
  const client = getMeiliSearchClient();
  // search for the query in the index

  const totalHits = [];
  let page = 0;
  while (total > totalHits.length) {
    const {
      hits,
      estimatedTotalHits
    } = await client.index(index).search(query, {
      ...options,
      offset: options.limit * page
    });
    for (const hit of hits) {
      const found = totalHits.findIndex(tHit => hit.relatedID == tHit.relatedID);
      if (found == -1) {
        totalHits.push(hit);
        if (total <= totalHits.length) break;
      }
    }
    page += 1;
    if (estimatedTotalHits && estimatedTotalHits < total) {
      break;
    }
  }
  return totalHits;
}
async function multiSearch(options) {
  const client = getMeiliSearchClient();
  // search for the query in the index
  const {
    indexUid = "deal"
  } = options;
  const {
    results
  } = await client.multiSearch({
    queries: [{
      q: options.query,
      indexUid,
      facets: options.facets,
      attributesToHighlight: options.attributesToHighlight ? options.attributesToHighlight : ["*"],
      highlightPreTag: "__ais-highlight__",
      highlightPostTag: "__/ais-highlight__",
      limit: options.limit ? options.limit : 20,
      offset: options.offset ? options.offset : 0,
      sort: options.sort ? options.sort : ["timestamp:desc"],
      matchingStrategy: "last",
      filter: options.filter,
      attributesToSearchOn: options.attributesToSearchOn ? options.attributesToSearchOn : ["*"],
      attributesToRetrieve: options.attributesToRetrieve ? options.attributesToRetrieve : ["*"]
    }]
  });
  return results;
}
async function multiSearchQuery(options) {
  const client = getMeiliSearchClient();
  const queries = options.map(opt => ({
    indexUid: "deal",
    attributesToHighlight: ["*"],
    highlightPreTag: "__ais-highlight__",
    highlightPostTag: "__/ais-highlight__",
    limit: 20,
    offset: 0,
    sort: ["timestamp:desc"],
    matchingStrategy: "all",
    ...opt
  }));

  // console.log(queries);

  // search for the query in the index
  const {
    results
  } = await client.multiSearch({
    queries
  });
  return results;
}
async function facetSearch(query, facetName, filter) {
  const client = getMeiliSearchClient();
  // search for the query in the index
  const {
    facetHits
  } = await client.index("deal").searchForFacetValues({
    facetQuery: query,
    facetName,
    filter,
    matchingStrategy: "all"
  });
  return facetHits;
}
async function getCategories(query, filter, limit) {
  const client = getMeiliSearchClient();
  // search for the query in the index
  const {
    hits
  } = await client.index("category").search(query, {
    limit: !!limit ? limit : 1000,
    // attributesToRetrieve: ["id", "title", "icon"],
    filter
  });
  return hits;
}
function roundToTwoDecimalPlaces(num, decimal = 2) {
  return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
}
const GermanNumberText = ({
  value,
  content,
  decimal,
  contentLeft,
  options,
  ...props
}) => {
  const formattedValue = typeof value == "number" ? roundToTwoDecimalPlaces(value, decimal).toLocaleString("de-DE", options).replaceAll(",00", "").replace(/\s| /g, "") : "";

  // console.log(formattedValue);

  return <Text {...props}>
      {contentLeft}
      {formattedValue}
      {content}
    </Text>;
};
_c = GermanNumberText;
const GermanNumber = ({
  value,
  content,
  decimal,
  contentLeft,
  options
}) => {
  const formattedValue = typeof value == "number" ? roundToTwoDecimalPlaces(value, decimal).toLocaleString("de-DE", options).replaceAll(",00", "").replace(/\s| /g, "") : "";
  return <>
      {contentLeft}
      {formattedValue}
      {content}
    </>;
};
_c2 = GermanNumber;
export { getMeiliSearchClient, search, multiSearch, getCategories, parseHitToDealHit, parseFacetDistribution, facetSearch, GermanNumber, GermanNumberText, parseHitToPromoHit, parseHitToCategory, parseHitToCommentHit, multiSearchQuery, searchTotal };
var _c, _c2;
$RefreshReg$(_c, "GermanNumberText");
$RefreshReg$(_c2, "GermanNumber");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;