// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/utils/affiliUtils.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/utils/affiliUtils.tsx");
  import.meta.hot.lastModified = "1709112347291.6187";
}
// REMIX HMR END

import { Box, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
export function affiliLinkUtil(link) {
  const affiliBackend = `${window.ENV.PUBLIC_DEAL_AFFILI_URL}/affiliate-generator`;
  const url = affiliBackend + "?" + new URLSearchParams([["link", link]]);
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": `application/json`
      }
    }).then(result => {
      if (result.status == 200) {
        return result.json();
      } else {
        return result.json().then(data => {
          throw new Error(data.error);
        });
      }
    }).then(resultJson => {
      resolve(resultJson.affiliateLink);
    }).catch(e => {
      console.log("error", e);
      reject(e);
    });
  });
}
export async function createShortLink(url, tag, dealID, source) {
  const data = await fetch(`${window.ENV.PUBLIC_DEAL_AFFILI_URL}/shorturl-api?url=${encodeURIComponent(url)}&tag=${tag}&dealID=${dealID || ""}&source=${source || ""}`).then(response => response.json()).catch(e => {
    console.log("error", e);
    return {
      shortURL: url
    };
  });
  ;
  return data.shortURL;
}
export function voucherUtil(code) {
  const adminServiceBackend = `/api/promocode/${code}`;
  return new Promise((resolve, reject) => {
    fetch(adminServiceBackend, {
      method: "GET",
      headers: {
        "Content-Type": `application/json`
      }
    }).then(result => {
      if (result.status == 200) {
        return result.json();
      } else {
        return result.json().then(data => {
          throw new Error(data.error);
        });
      }
    }).then(resultJson => {
      resolve(resultJson);
    }).catch(e => {
      console.log("error", e);
      reject(e);
    });
  });
}
export const CloakingMap = [{
  type: "deal",
  field: "affiliateLink",
  isURL: true
}, {
  type: "deal",
  field: "content",
  isURL: false
}, {
  type: "comment",
  field: "content",
  isURL: true
}];
function getCloakingMapping(typeMap, fieldMap) {
  return CloakingMap.findIndex(entry => entry.field == fieldMap && entry.type == typeMap);
}
export function CloakingLink(props) {
  _s();
  const {
    type,
    field,
    children,
    href,
    target = "_blank",
    entryId
  } = props;
  const [cloakingLink, setCloakingLink] = useState(href);
  useEffect(() => {
    if (href && entryId) {
      const cloakedIndex = getCloakingMapping(type, field);
      setCloakingLink(window.ENV.PUBLIC_REDIRECT_SITE_URL + `/link/${cloakedIndex}/${entryId}/0`);
    }
  }, [href]);
  return href && href != "" && <Link target={target} href={cloakingLink}>
      {children}
    </Link>;
}
_s(CloakingLink, "jx/DEyYw8boyRgAtup58czFpeak=");
_c = CloakingLink;
function extractAndReplaceLinks(htmlText, baseUrl, postId, cloakedIndex) {
  const originalLinks = [];
  let linkIndex = 1;
  const updatedHtmlText = htmlText.replace(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi, (match, p1, p2) => {
    originalLinks.push(p2); // Storing the original link
    const newLink = `${baseUrl}/link/${cloakedIndex}/${postId}/${linkIndex}`;
    linkIndex++;
    return match.replace(p2, newLink);
  });
  return {
    updatedHtmlText,
    originalLinks
  };
}
function getCloakingContent(content, cloakedIndex, entryId, target) {
  return extractAndReplaceLinks(content, window.ENV.PUBLIC_REDIRECT_SITE_URL, entryId, cloakedIndex);
}
export function CloakingContent(props) {
  _s2();
  const {
    type,
    field,
    target,
    entryId,
    content
  } = props;
  const [cloakingContent, setCloakingContent] = useState(content);
  useEffect(() => {
    if (content && entryId) {
      const cloakedIndex = getCloakingMapping(type, field);
      const cloakedContent = getCloakingContent(cloakingContent, cloakedIndex, entryId, target);
      setCloakingContent(cloakedContent.updatedHtmlText);
    }
  }, [content]);
  const boxProps = {
    ...props
  };
  delete boxProps.entryId;
  return <Box {...boxProps} dangerouslySetInnerHTML={{
    __html: cloakingContent
  }} />;
}
_s2(CloakingContent, "gzHR1BYxrAuUvKUMUHot2ktXBiQ=");
_c2 = CloakingContent;
var _c, _c2;
$RefreshReg$(_c, "CloakingLink");
$RefreshReg$(_c2, "CloakingContent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;